.item {
  a {
    transition: 0.5s all;
  }

  :global .active {
    color: #3e8ed0;
    border-bottom: 1px solid #3e8ed0;
  }
}
