.container {
  text-align: center;
  margin: 1em 0;
}

.c-loader {
  width: 38px;
  height: 38px;
  border: 1px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
