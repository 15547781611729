details {
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.title {
  background: #ece9e9;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 6px 6px 0 0;
}

.content {
  padding: 1em;
}
