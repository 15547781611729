

$np_dark: #333;
$np_yellow: #f5c50d;

$link: $np_dark;

$pink: #ffb3b3;
$pink-invert: #fff;

$primary: $np_yellow;
$info: $np_yellow;
$primary-invert: $pink-invert;



@import 'bulma/bulma.sass';
@import 'bulma-quickview/dist/css/bulma-quickview.min.css';
@import 'bulma-list/css/bulma-list.css';
@import 'bulma-switch/dist/css/bulma-switch.min.css';