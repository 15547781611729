.add-panel {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;

  &.open {
    margin: 1em 0;
    height: auto;
    animation: fadein 2s forwards;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
