.add-panel {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.5s;

  &.open {
    margin: 1em 0;
    height: auto;
    animation: fadein 2s forwards;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.list-item {
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  &:nth-child(odd) {
    background: #f8f8f8;
  }
}

.checkbox-outer {
  display: flex;
  align-items: center;
}

.buffer {
  input {
    margin-right: 8px;
  }
}
