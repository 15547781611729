.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login {
  display: flex;
  min-height: 40vh;
  min-width: 40%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  flex: 0 auto;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.brand {
  background: url('./bg.jpg') no-repeat bottom;
  background-size: cover;
  min-width: 50%;
  //animation: 10s infinite alternate zoomBg;
}

.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: auto;
  padding: 1.5em;
}

.title {
  color: #f5c50d;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 1em;
}

.btn {
  margin-top: 2em;
  padding-top: 1em;
  border-top: 1px solid #ccc;
}

@keyframes zoomBg {
  from {
    background-size: 100%;
  }

  to {
    background-size: 110%;
  }
}
